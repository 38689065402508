<template>
<!-- 素材列表页 -->
<div class="articleList">
    <el-card>
    <div v-show="articleType == 1">
        <!-- 搜索框 -->
        <div class="div-height div">
        <div class="left">
            <!-- <el-button
                        class="themeButton"
                        @click="handleOpen('is_add', '添加素材')"
                        >添加素材</el-button
                    > -->
            <el-button class="themeButton" @click="handleOpen('is_add_category', '添加目录')">添加目录</el-button>
        </div>
        <!-- <div class="right">
                    <el-input
                        placeholder="请输入内容"
                        v-model="input"
                        clearable
                        class="input-div"
                    >
                    </el-input>
                    <el-button type="primary" @click="searchContent()"
                        >搜索</el-button>
                </div> -->
        <!-- <div class="expSearch">  
                    <el-input
                        clearable
                        placeholder="请输入内容"
                        v-model="input"
                        @clear="searchContent">
                        <el-button slot="append" class="el-icon-search" @click="searchContent" ></el-button>
                    </el-input>
                </div> -->
        </div>
        <!-- 表格 -->
        <div class="div">
        <!-- default-expand-all 树形图打开属性 -->
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px">
            <!-- 分类名称 -->
            <!-- <el-table-column label="排序" sortable>
                        <template slot-scope="scope">
                            <span>
                                <el-input
                                    v-model="scope.row.sort_no"
                                    @blur="sortNoUpdate(scope.row)"
                                    style="width:80px"
                                ></el-input>
                            </span>
                        </template>
                    </el-table-column> -->
            <el-table-column type="index" label="序号" width="70"></el-table-column>
            <el-table-column label="目录">
            <template slot-scope="scope">
                <div class="bookList" @click="enter(scope.row.id, scope.row.name)">{{ scope.row.name }}</div>
            </template>
            </el-table-column>
            <!-- <el-table-column label="类型">
                        <template slot-scope="scope">
                            <span v-if="!('is_folder' in scope.row)">素材</span>
                            <span v-else>目录</span>
                        </template>
                    </el-table-column> -->
            <el-table-column label="操作">
            <template slot-scope="scope">
                <div class="operateItem">
                <!-- <span v-if="!('is_folder' in scope.row)">
                                <div class="operatorItem floatLeft" @click="handleOpen('is_qrcode','二维码',scope.$index,scope.row)">二维码</div>
                                <div class="operatorItem floatLeft" @click="handleOpen('is_update','编辑素材',scope.$index,scope.row)">编辑</div>
                                <div class="operatorDel floatLeft" @click="handleOpen('is_delete','删除素材',scope.$index,scope.row)">删除</div>
                            </span> -->
                <span>
                    <div class="operatorItem floatLeft" @click="handleShare(scope.row)">共享</div>
                    <div class="operatorItem floatLeft" @click="handleOpen('is_update_category', '编辑目录', scope.$index, scope.row)">重命名</div>
                    <!-- <div class="operatorItem floatLeft" @click="enter(scope.row.id,scope.row.name)">查看</div> -->
                    <div class="operatorDel floatLeft" @click="handleOpen('is_delete_category', '删除目录', scope.$index, scope.row)">删除</div>

                    <!-- <el-button
                                    size="mini"
                                    type="primary" plain
                                    @click="
                                        handleOpen(
                                            'is_delete_category',
                                            '删除目录',
                                            scope.$index,
                                            scope.row
                                        )
                                    "
                                    >删 除</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="primary" plain
                                    @click="
                                        handleOpen(
                                            'is_update_category',
                                            '编辑目录',
                                            scope.$index,
                                            scope.row
                                        )
                                    "
                                    >编 辑</el-button
                                > -->
                </span>
                </div>
            </template>
            </el-table-column>
        </el-table>
        </div>
    </div>
    <div v-show="articleType == 2">
        <div class="div">
        <div class="left enterarticle">
            <div class="foldername">目录：{{ foldername }}</div>
            <el-button class="themecancelButton" @click="back">返回</el-button>
            <el-button class="themeButton" @click="handleOpen('is_add', '添加资源')">添加资源</el-button>
        </div>
        <div class="expSearch">
            <el-input clearable placeholder="请输入内容" v-model="input" @clear="searchContent">
            <el-button slot="append" class="el-icon-search" @click="searchContent"></el-button>
            </el-input>
        </div>
        <el-table :data="articleList">
            <el-table-column type="index" label="序号" width="70"></el-table-column>
            <el-table-column prop="title" label="名称"></el-table-column>
            <el-table-column label="操作">
            <template slot-scope="scope">
                <div class="operateItem">
                <span>
                    <div class="operatorItem floatLeft" @click="handleShareShare(scope.row)">共享</div>
                    <div class="operatorItem floatLeft" @click="handleOpen('is_qrcode', '二维码', scope.$index, scope.row)">二维码</div>
                    <div class="operatorItem floatLeft" @click="handleOpen('is_update', '编辑素材', scope.$index, scope.row)">编辑</div>
                    <div class="operatorDel floatLeft" @click="handleOpen('is_delete', '删除资源', scope.$index, scope.row)">删除</div>
                </span>
                </div>
            </template>
            </el-table-column>
        </el-table>
        <div class="">
            <el-pagination
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
            :current-page.sync="page"
            :total="dataTotal"
            v-if="dataTotal != 0"
            ></el-pagination>
        </div>
        </div>
    </div>
    </el-card>

    <el-dialog title="共享弹窗" :visible.sync="dialogShareVisibleShare" width="30%">
        <span>
            <span style="font-size: 0.8333vw">请选择要共享的同事：</span>
            <br />
            <br />
            <el-select v-model="selectTogetherShare" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="item in togetherListShare" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </span>
        <span slot="footer" class="dialog-footer" v-if="handleSign != 'is_qrcode'">
            <el-button class="themecancelButton" @click="dialogShareVisibleShare = false">取 消</el-button>
            <el-button class="themeButton" @click="dialogShareFixShare()">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog title="共享弹窗" :visible.sync="dialogShareVisible" width="30%">
        <span>
            <span style="font-size: 0.8333vw">请选择要共享的同事：</span>
            <br />
            <br />
            <el-select v-model="selectTogether" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="item in togetherList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </span>
        <span slot="footer" class="dialog-footer" v-if="handleSign != 'is_qrcode'">
            <el-button class="themecancelButton" @click="dialogShareVisible = false">取 消</el-button>
            <el-button class="themeButton" @click="dialogShareFix()">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
    <span>
        <span v-if="handleSign != 'is_delete' && handleSign != 'is_delete_category' && handleSign != 'is_qrcode' && handleSign != 'is_add'">
        <el-input v-model="categoryName" placeholder="请输入目录名称"></el-input>
        </span>
        <span v-else-if="handleSign == 'is_add'">
        <div class="p-add">资源名称：</div>
        <div class="p-add2">
            <el-input v-model="categoryName" placeholder="请输入资源名称"></el-input>
        </div>

        <!-- <br />
                    <br /> -->
        <!-- <div class="p-add">所属目录：</div> -->
        <!-- <div class="p-add2">
                        <el-select
                            v-model="folderid"
                            placeholder="请选择所属目录"
                            class="input-width"
                            style="width:100%">
                            <el-option
                                v-for="item in categoryList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div> -->
        <!-- <p class="p-add">素材分类</p>
                    <el-select
                        v-model="folderid"
                        placeholder="请选择素材分类"
                        class="input-width"
                        style="width:100%"
                    >
                        <el-option
                            v-for="item in categoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
        </span>
        <span v-else-if="handleSign == 'is_qrcode'">
        <p class="dialog-p">{{ articleTitle }}</p>
        <img :src="qrcode" alt="" style="width: 100%" />
        <!-- logoSrc为logo的url地址(使用require的方式)；text为需要转换为二维码的内容 -->
        <vue-qr :logoSrc="schoolLogo" :text="qrCodeUrl" :size="800" style="width: 100%"></vue-qr>
        <p :data-clipboard-text="articleUrl" class="dialog-p cursor icon-copy" @click="copyLink">点击复制链接</p>
        </span>
        <span v-else class="dialog-span">您确定要删除么？</span>
    </span>
    <span slot="footer" class="dialog-footer" v-if="handleSign != 'is_qrcode'">
        <el-button class="themecancelButton" @click="dialogVisible = false">取消</el-button>
        <el-button class="themeButton" @click="dialogFix()">确定</el-button>
    </span>
    </el-dialog>
</div>
</template>

<style>
.articleList .el-pagination.is-background .el-pager li:not(.disabled).active {
background-color: #0d5570;
color: #fff;
}
.articleList .el-table__body-wrapper {
height: 70vh !important;
overflow: scroll !important;
scrollbar-width: none !important;
-ms-overflow-style: none !important;
}
.articleList .el-table__body-wrapper::-webkit-scrollbar {
display: none !important;
}
.articleList .el-dialog {
border-radius: 1vw;
}
.articleList .el-dialog__body {
padding: 1.5625vw 1.0417vw;
padding-top: 0.2604vw !important;
color: #606266;
font-size: 0.7292vw;
word-break: break-all;
padding-bottom: 1.5625vw;
/* text-align: center; */
}
.articleList .el-table__row {
height: 7vh;
}
.articleList .el-table {
font-size: 0.8333vw !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/teacher/article/articleList.scss';
</style>

<script>
import articleList from '@/assets/js/teacher/article/articleList.js';
export default {
...articleList,
};
</script>
