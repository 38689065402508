import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { startLoading, endLoading, sleep } from "@/utils/function";
import API from "@/api/teacher/article/articleList"
import Clipboard from "clipboard";
import vueQr from "vue-qr";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";

export default {
    name: "other",
    components: {
        vueQr
    },
    inject:['reload'],
    data() {
        return {
            articleList:[],
            input: "",
            page: 1,
            pagesize:10,
            dataTotal: 0,
            tableData: [],
            tableData2:[],
            dialogVisible: false,
            article_id: "",
            handleSign: "",
            categoryName: "",
            dialogTitle: "",
            sort_no: 0,
            qrcode: "",
            articleTitle: "",
            categoryList: "",
            folderid: "",
            articleUrl:'',
            qrCodeUrl:'xxx',
            schoolLogo:'xxx',
            sortIndex:'',
            sortNum:'',
            id:'',
            is_folder:'',
            articleType:1,
            foldername:'',

            togetherList:[],
            selectTogether:[],
            id:0,
            dialogShareVisible:false,
            dialogShareVisibleShare:false,
            idShare:"",
            selectTogetherShare:[],
            togetherListShare:[],


        };
    },
    created() 
    {
        const self = this;
        self.folderid = this.$router.currentRoute.query.id?this.$router.currentRoute.query.id:'';
        self.input = this.$router.currentRoute.query.search?this.$router.currentRoute.query.search:'';
        self.articleType = this.$router.currentRoute.query.articleType?this.$router.currentRoute.query.articleType:1;
        self.page = this.$router.currentRoute.query.page?Number(this.$router.currentRoute.query.page):1;
        self.foldername = this.$router.currentRoute.query.foldername?this.$router.currentRoute.query.foldername:'';
        if(self.articleType == 1 )
        {
            self.getData();
        }
        else
        {
            self.getArticleList();
        }
    },
    mounted()
    {
        let self=this;
        self.rowDrop();
    },
    methods: 
    {
        dialogShareFixShare()
        {
            const self = this;
            let datas = {
                together_id: self.selectTogetherShare,
                id: self.idShare
            };
            nprogressStart();
            console.log(datas);
            self.axios
                .post("/index.php/backarticle/shareArticleById",datas)
                .then((result)=>{
                self.$Loading.hide();
                nprogressClose();
                if(result.data.code > 0)
                {
                    self.dialogShareVisibleShare = false;
                }
                else
                {
                    self.$Tips({
                    // 消息提示内容
                    message: result.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 消失时间
                    displayTime: 1500
                    });return;
                }
            })
        },

        dialogShareFix()
        {
            const self = this;
            let datas = {
                together_id: self.selectTogether,
                id: self.id
            };
            nprogressStart();
            console.log(datas);
            console.log(self.selectTogether);
            self.axios
                .post("/index.php/backarticle/shareArticleByCategory",datas)
                .then((result)=>{
                self.$Loading.hide();
                nprogressClose();
                if(result.data.code > 0)
                {
                    self.dialogShareVisible = false;
                }
                else
                {
                    self.$Tips({
                    // 消息提示内容
                    message: result.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 消失时间
                    displayTime: 1500
                    });return;
                }
            })
        },

        handleShareShare(row)
        {
            const self = this;
            self.dialogShareVisibleShare = true;
            self.idShare = row.id;
            console.log(row);
        },

        handleShare(row)
        {
            const self = this;
            self.dialogShareVisible = true;
            self.id = row.id;
            console.log(row);
        },

        indexMethod(index) {
            let currentPage = this.certPage - 0; // 当前页码
            let pageSize = this.certPageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },

        back()
        {
            this.articleType = 1;
            this.$router.push({
                path: "/teacher/articleList",
            }).then(()=>{
                this.reload()
            });
        },

        enter(id,name) {
            this.folderid = id;
            this.foldername = name;
            this.articleType = 2;
            this.getArticleList();
            
        },

        getArticleList()
        {
            let self = this;
            let data = {
                page:self.page,
                search:self.input,
                folderid: self.folderid
            };
            self.$router.push({
                path: "/teacher/articleList",
                query: {
                    id:self.folderid,
                    search: self.input,
                    page:self.page,
                    articleType:self.articleType,
                    foldername:self.foldername
                }
            }).then(()=>{
                // self.reload()
            });
            // 请求数据
            API.getArticleList(data)
                    .then((result)=>{
                        self.$Loading.hide();
                        if(result.code > 0)
                        {
                            self.articleList = result.articleList;
                            self.dataTotal = result.count;
                            self.togetherListShare = result.togetherList;
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'warning',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        
                    })

        },

        handleCurrentChange(val) {
            const self = this;
            self.page = val;
            self.getArticleList();
        },

        getrowIndex(row)
        {
            let self = this;
            let id = row.id
            let sortIndex = ''
            let sortNum = 0
            let tableData3 = self.tableData
            self.tableData2.forEach((item,index)=>{
                if(item.id == id)
                {
                    sortIndex = index
                }
                else
                {
                    item.children.forEach((value,key)=>{
                        if(value.id == id)
                        {
                            sortIndex = index
                        }
                    })
                }
            })
            for (let i = 0; i < sortIndex; i++) {
                sortNum += tableData3[i].children.length
            }
            self.sortIndex = sortIndex
            self.sortNum = sortNum+sortIndex+1
            self.id = id
            self.is_folder = row.is_folder??false
        },

        compare(property)
        {
            return function(a,b)
                    {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value2 - value1;
                    }
        },

        rowDrop() 
        {
            const self = this;
            const tbody = document.querySelector(".el-table__body-wrapper tbody");
            const sortable = new self.Sortable(tbody, {
                onEnd: (evt) => { // 监听拖动结束事件
                    let data = {};
                    let sortNo = '';
                    let id = '';
                    if(self.is_folder == true)
                    {
                        return;
                    }
                    else
                    {
                        // console.log('新排序'+evt.newIndex,'旧排序'+evt.oldIndex);
                        // console.log('当前第几层'+self.sortIndex);
                        // console.log('需要去掉的数据'+self.sortNum);
                        // console.log(evt);
                        // evt.oldIndex——当前行的被拖拽前的顺序;
                        // evt.newIndex——当前行的被拖拽后的顺序;
                        if(evt.oldIndex < evt.newIndex)
                        {
                            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果;
                            window.sessionStorage.setItem('loading','false');
                            if(evt.newIndex - self.sortNum == self.tableData2[self.sortIndex].children.length - 1 )
                            {
                                sortNo = self.tableData2[self.sortIndex].children[self.tableData2[self.sortIndex].children.length - 1].sort_no - 1;
                                self.tableData2[self.sortIndex].children[self.tableData2[self.sortIndex].children.length - 1].sort_no = sortNo;
                                // 防止拖拽后从后台获取最新数据页面刷新,影响用户体验,前端处理排序
                                self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                            }
                            else
                            {
                                let sortbefore = self.tableData2[self.sortIndex].children[evt.newIndex-self.sortNum].sort_no - 0;
                                let sortnext = self.tableData2[self.sortIndex].children[evt.newIndex-self.sortNum+1].sort_no - 0;
                                sortNo = (sortbefore+sortnext) / 2;
                                self.tableData2[self.sortIndex].children[evt.oldIndex-self.sortNum].sort_no = sortNo;
                                self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                            }   
                            data = {
                                id: self.id,
                                sort_no: sortNo,
                            };
                        }
                        else
                        {
                            if(evt.newIndex == self.sortNum)
                            {
                                sortNo = self.tableData2[self.sortIndex].children[0].sort_no - 0 + 1;
                                self.tableData2[self.sortIndex].children[0].sort_no = sortNo;
                                self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                            }
                            else
                            {
                                let sortbefore = self.tableData2[self.sortIndex].children[evt.newIndex-self.sortNum].sort_no - 0;
                                let sortnext = self.tableData2[self.sortIndex].children[evt.newIndex-self.sortNum - 1].sort_no - 0;
                                sortNo = (sortbefore+sortnext) / 2;
                                self.tableData2[self.sortIndex].children[evt.oldIndex-self.sortNum].sort_no = sortNo;
                                self.tableData2 = self.tableData2.sort(self.compare('sort_no'));
                            }
                                // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果
                                window.sessionStorage.setItem('loading','false');
                                data = {
                                    id: self.id,
                                    sort_no: sortNo,
                                };
                        };
                        API.updateSortArticle(data)
                        .then((result)=>{
                            if(result.code > 0)
                            {
                                window.sessionStorage.setItem('loading','true')
                            }
                            self.getData();
                        })
                    }
                    
                }
            })
        },

        copyLink()
        {
            const clipboard = new Clipboard(".icon-copy");
            clipboard.on("success", (e) => {
                // Message.success("复制成功！");
            });
            // Message.success("复制成功！");
            this.$Tips({
                // 消息提示内容
                message:'复制成功',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                displayTime:1500
            })
            
        },

        getCategoryList() 
        {
            const self = this;
            let data = {};
            // 请求数据
            API.getCategoryList(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.categoryList = result.categoryList;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
        },

        searchContent() {
            const self = this;
            self.page = 1;
            // 查询列表
            self.getArticleList();
        },

        handleOpen(sign, title = "", index = "", row = "") {
            const self = this;
            self.handleSign = sign;
            self.dialogTitle = title;
            if (sign == "is_delete") {
                self.dialogVisible = true;
                self.article_id = row.id;
            } else if (sign == "is_delete_category") {
                self.dialogVisible = true;
                self.category_id = row.id;
            } else if (
                sign == "is_add_category" ||
                sign == "is_update_category"
            ) {
                self.categoryName = ''
                if (sign == "is_update_category") {
                    self.category_id = row.id;
                    self.getCategoryInfo();
                }
                // self.category_id = '';
                self.dialogVisible = true;
            } else if (sign == "is_qrcode") {
                self.article_id = row.id;
                // 获取二维码
                // self.getArticleQrcode();
                self.qrCodeUrl = row.url;
                self.schoolLogo = row.pic;
                self.dialogVisible = true;
                self.articleTitle = row.title;
                // self.articleUrl = "https://www.mools.net/lims/web/teacher/new_zyxq.html?id="+self.article_id;
                self.articleUrl = "https://app.mools.net/lims/article/index.html?article_id="+self.article_id;
            } else if (sign == "is_add") {
                self.dialogVisible = true;
                self.categoryName = ''
            } else if (sign == "is_update") {
                self.article_id = row.id;
                window.open('#/teacher/article/articleUpdate?article_id='+self.article_id +
                '&name=' +  row.title + 
                '&hideMenu=' + 1 + 
                '&type=' +  1 + 
                '&id=' +  self.$router.currentRoute.query.id + 
                '&search=' +  self.$router.currentRoute.query.search + 
                '&page=' +  self.$router.currentRoute.query.page + 
                '&foldername=' +  self.$router.currentRoute.query.foldername)
            }
        },

        getArticleQrcode() 
        {
            const self = this;
            let data = {
                article_id: self.article_id
            };
            // 请求数据
            API.getArticleQrcode(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.qrcode = result.url;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
        },

        getCategoryInfo() {
            const self = this;
            let data = {
                category_id: self.category_id
            };
            // 请求数据
            API.getCategoryInfo(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.categoryName = result.info.name;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
        },

        dialogFix() {
            const self = this;
            let data = {
                article_id: self.article_id,
                categoryName: self.categoryName,
                category_id: self.category_id
            };
            if (self.handleSign == "is_delete") 
            {
                // 开启loading
                // startLoading();
                self.$Loading.show()
                // 请求数据
                API.deleteArticle(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getArticleList();
                            self.dialogVisible = false;
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                        
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                        
                    });
                    self.$Loading.hide();

            } 
            else if (self.handleSign == "is_delete_category") 
            {
                // 开启loading
                // startLoading();
                self.$Loading.show()
                // 请求数据
                API.deleteExec(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        else
                        {
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
                    self.$Loading.hide();

            } 
            else if (self.handleSign == "is_update_category" || self.handleSign == "is_add_category") 
            {
                // 开启loading
                // startLoading();
                self.$Loading.show()
                // 请求数据
                API.updateArticleCategory(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.getCategoryList();
                            self.dialogVisible = false;
                            self.categoryName = "";
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
                    self.$Loading.hide();
            } 
            else if (self.handleSign == "is_add") 
            {
                let data = {
                    folderid: self.folderid,
                    title: self.categoryName
                };
                if (self.categoryName == "") {
                    // Message.warning("请填写素材标题");
                    this.$Tips({
                        // 消息提示内容
                        message:"请填写素材标题",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                    return;
                } else if (self.folderid == "") {
                    // Message.warning("请选择素材分类");
                    this.$Tips({
                        // 消息提示内容
                        message:"请选择素材分类",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                    return;
                }
                // 开启loading
                // startLoading();
                self.$Loading.show()
                // 请求数据
                API.saveArticle(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.article_id = result.data.id;
                            // 关闭loading
                            // endLoading(startLoading()); 
                            self.$Loading.hide();
                            window.open('#/teacher/article/articleUpdate?article_id='+self.article_id+'&name='+self.categoryName+'&hideMenu=1')
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
                    self.$Loading.hide();
            }
        },

        handleCurrentChange(val) {
            const self = this;
            self.page = val;
            self.getArticleList();
        },

        sortNoUpdate(param) 
        {
            const self = this;
            let data = {
                id: param.id,
                sort_no: param.sort_no
            };
            // 请求数据
            API.updateSortArticle(data)
                .then((result)=>{
                    if(result.code > 0)
                    {
                        self.getData();
                    }
                    // 关闭loading
                    // endLoading(startLoading()); 
                    self.$Loading.hide();
                })
                .catch(err => {
                    // 关闭loading
                    // endLoading(startLoading()); 
                    self.$Loading.hide();
                });
        },

        getData() 
        {
            const self = this;
            // 开启loading
            self.$Loading.show()
            let data = {
                page: self.page,
                search: self.input
            };
            // 请求数据
            API.getCategoryList(data)
                .then((result)=>{
                    if(result.code > 0)
                    {
                        self.tableData = result.categoryList;
                        self.tableData2 = result.categoryList;
                        self.togetherList = result.togetherList;
                    }
                    // 关闭loading
                    self.$Loading.hide();
                })
                .catch(err => {
                    // 关闭loading
                    self.$Loading.hide();
                });
        }
    }
};
