import { request } from "@/request/http";

export default {
  getCategoryList: (data) => {
    return request("GET", `/index.php/backarticle/getCategoryList`, data);
  },

  getArticleQrcode: (data) => {
    return request("GET", `/index.php/backarticle/getArticleQrcode`, data);
  },

  getCategoryInfo: (data) => {
    return request("GET", `/index.php/backarticle/getCategoryInfo`, data);
  },

  deleteArticle: (data) => {
    return request("GET", `/index.php/backarticle/deleteArticle`, data);
  },

  deleteExec: (data) => {
    return request("GET", `/index.php/backarticle/deleteExec`, data);
  },

  updateArticleCategory: (data) => {
    return request("GET", `/index.php/backarticle/updateArticleCategory`, data);
  },

  saveArticle: (data) => {
    return request("GET", `/index.php/backarticle/saveArticle`, data);
  },

  updateSortArticle: (data) => {
    return request("GET", `/index.php/backarticle/updateSortArticle`, data);
  },

  getArticleList: (data) => {
    return request("GET", `/index.php/backarticle/getArticleList`, data);
  },
};